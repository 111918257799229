import { apiFactory } from '@/utils/constant'

const fieldManagementApi = {
  list: '/orderService/exportFieldManagement/list', //
  update: '/orderService/exportFieldManagement/update'
}

for (const key in fieldManagementApi) {
  fieldManagementApi[key] = apiFactory(fieldManagementApi[key])
}

export default fieldManagementApi