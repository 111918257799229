<template>
  <div class="EditBtn">
    <ColorTextBtn @click="showDialog" :disabled="!!row.isEnable"> 编辑 </ColorTextBtn>
    <el-dialog :visible.sync="dialogVisible" append-to-body title="编辑" width="30%">
      <div>
        <avue-form v-model="form" ref="ruleForm" :option="option"></avue-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editHandle" :loading="loading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { option } from './option'
import fieldManagementApi from '@/api/system/fieldManagementApi'

export default {
  name: 'EditBtn',
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      }
    },
    init: Function
  },
  components: {},
  data() {
    return {
      dialogVisible: false,
      option,
      form: {
        fieldName: '',
        sortNumber: ''
      },
      loading: false
    }
  },
  computed: {},
  methods: {
    showDialog() {
      this.form.fieldName = this.row.fieldName
      this.form.sortNumber = this.row.sortNumber
      this.dialogVisible = true
    },
    editHandle() {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const query = {
            id: this.row.id,
            ...this.form,
            templateUsageType:1
          }
          try {
            this.loading = true
            const res = await fieldManagementApi.update(query)
            if ($SUC(res)) {
              this.$message.success('操作成功')
              this.dialogVisible = false
              this.init()
            } else {
              this.$message.error('操作失败')
            }
          } catch {
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.EditBtn {
  display: inline-block;
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ccc;

    .el-dialog__title {
      font-size: 16px;
    }
  }
}
</style>
